import React from "react"
import BackgroundImage from "gatsby-background-image"
import PropTypes from "prop-types"
import * as BannerStyles from "./Banner.module.css"

function Banner(props) {
    return (
        <BackgroundImage className={BannerStyles.banner} fluid={props.image}>
            {props.children}
        </BackgroundImage>
    )
}

Banner.propTypes = {
    image: PropTypes.string.isRequired,
}

export default Banner