import React from "react"
import * as UnderlineStyles from "./Underline.module.css"

export default function Underline(props) {
    return (
        <div className={UnderlineStyles.container}>
            {props.children}
            <div className={UnderlineStyles.underline}></div>
        </div>
    )
}